import { graphql } from 'gatsby'

import { CardTextBlock, FlexCard } from '../Card'
import { IconBlockList } from '../IconBlockList'
import { EntryLink } from '../EntryLink'

const HazardousCargo = (props) => {
  const { title, subtitle, ghsPictogramsCollection, linksCollection } = props
  const icons = ghsPictogramsCollection.items.filter(Boolean).map((item) => {
    return {
      icon: {
        ...item.pictogram,
      },
      title: item.pictogramText,
    }
  })
  return (
    <FlexCard
      action={linksCollection.items.map((item, index) => {
        return <EntryLink {...item} key={index} />
      })}
    >
      <CardTextBlock title={title} text={subtitle} />
      <IconBlockList
        icons={icons}
        hasBorders={false}
        iconWidth={100}
        noMarginBottom
      />
    </FlexCard>
  )
}

export const query = graphql`
  fragment HazardousCargo on contentful_HazardousCargo {
    subtitle
    ghsPictogramsCollection(limit: 10) {
      items {
        pictogramText
        pictogram {
          ...Asset
        }
      }
    }
    linksCollection(limit: 10) {
      items {
        ...ItemLink
      }
    }
  }
`

export default HazardousCargo
